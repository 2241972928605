/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column style={{"paddingBottom":35,"paddingTop":64}} name={"fotonadpis"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Fotogalerie"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":960}} content={"<span style=\"color: var(--color-custom-1);\">Okamžiky, které mluví samy za sebe. <br>Prohlédněte si atmosféru našich svateb – romantické prostředí, emoce, radost <br>a láska zachycené ve fotografiích.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":63,"paddingBottom":71}} name={"foto1"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/7fc049e648344dc68fe3e99b88623147_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/90dae6d795dc4439b48afc04663d37e2_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/ed21065f0cc5430eb0505b807f023b7b_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/fc76b576de94464b9c3b39ea9cf32965_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l8"} style={{"paddingBottom":34,"paddingTop":54}} name={"foto2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/69c8058d7b8b4ababff5584a1f0c37f7_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/69c8058d7b8b4ababff5584a1f0c37f7_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/a1db6c4bc52644239ade830fbd0ed909_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/a1db6c4bc52644239ade830fbd0ed909_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/a1db6c4bc52644239ade830fbd0ed909_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/a1db6c4bc52644239ade830fbd0ed909_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/5675f7f76c114f2d965fcc2e8716ee45_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5675f7f76c114f2d965fcc2e8716ee45_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/5675f7f76c114f2d965fcc2e8716ee45_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/5675f7f76c114f2d965fcc2e8716ee45_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":89,"paddingBottom":79}} name={"foto3"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7361/259276ba4aa14dad976896e1d8a9f3dc_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/fed431ca8797477dac17cf3dbfe6a27a_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7361/aa63378112f24de1bf8bc618ff01654f_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/b1e8aef598fc44aaa5086eedd05b3a86_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/b1e8aef598fc44aaa5086eedd05b3a86_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/b1e8aef598fc44aaa5086eedd05b3a86_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7361/b1e8aef598fc44aaa5086eedd05b3a86_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7361/b1e8aef598fc44aaa5086eedd05b3a86_s=1400x_.JPG 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":76,"paddingBottom":136}} layout={"l8"} name={"foto4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/09dce9495be443b2830d2709731abb62_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/09dce9495be443b2830d2709731abb62_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/09dce9495be443b2830d2709731abb62_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/09dce9495be443b2830d2709731abb62_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/d073b2bbb7614c12a54d5e4f6a1e7adb_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/37819009240c49e0b5a9b002edae065e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/37819009240c49e0b5a9b002edae065e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/37819009240c49e0b5a9b002edae065e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/37819009240c49e0b5a9b002edae065e_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/eb4e7270c1f6423cad5aea9acbb9af18_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}